







































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { PasswordValidation } from '../../utils/password-validation';

@Component
export default class AccountPassword extends Vue {
  public current = '';
  public password1 = '';
  public password2 = '';

  public get passwordValidation1() {
    return new PasswordValidation(this.password1).validateMin();
  }

  public get passwordValidation2() {
    return new PasswordValidation(this.password1).validateDigit();
  }

  public get passwordValidation3() {
    return new PasswordValidation(this.password1).validateCharacters();
  }

  public get passwordValidation4() {
    return (
      this.passwordValidation1 &&
      this.passwordValidation2 &&
      this.passwordValidation3 &&
      this.password1 === this.password2
    );
  }

  public async changePassword() {
    if (!this.passwordValidation4) return;

    await this.$firebase.account.password(this.password1);
    await this.$bce.message(this.$t('changes-saved'), 1, 'primary');
    this.$router.back();
  }

  public async displayError() {
    await this.$bce.message(this.$t('password-change-error'), 5, 'error');
  }

  public getPasswordValidationClass(valid: boolean) {
    if (!this.password1) return '';
    return `password-validation-${valid ? 'true' : 'false'}`;
  }
}
